import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './SliderDots.module.scss'

const colors = {
  brown: 'border-gold-100',
  gold: 'border-brown-100',
  goldWithBg: 'border-brown-100',
  goldOnDark: 'border-gold-100',
  goldOnDarkWithBg: 'border-gold-100',
  gray: 'border-red-100',
  grayWithBg: 'border-red-100',
  grayDark: 'border-brown-200',
  homeHero: 'border-gray-200',
}

const activeColors = {
  brown: 'bg-gold-100',
  gold: 'bg-brown-100',
  goldWithBg: 'bg-brown-100',
  goldOnDark: 'bg-gold-100',
  goldOnDarkWithBg: 'bg-gold-100',
  gray: 'bg-red-100',
  grayWithBg: 'bg-red-100',
  grayDark: 'bg-brown-200',
  homeHero: 'bg-gray-200',
}

const classes = {
  container: [
    'flex',
    'flex-col',
    'justify-center',
    'fixed',
    'top-0',
    'right-0',
    'bottom-0',
    'mr-6',
    'leading-none',
    'z-50',
    //
  ].join(' '),
  button: [
    'relative',
    'border',
    'focus:outline-none',
    //
  ].join(' '),
}

const SliderDots = ({ number, activeIndex, onClick, headerTheme, hidden }) => {
  const arr = Array.apply(null, Array(number)).map((_, i) => i)
  return (
    <div
      className={[
        classes.container,
        styles.container,
        hidden ? styles.hidden : '',
      ].join(' ')}
    >
      {arr.map(i => {
        return (
          <div key={i} className={i < arr.length - 1 ? 'mb-1' : ''}>
            <button
              type='button'
              className={[
                classes.button,
                colors[headerTheme],
                styles.button,
                activeIndex === i ? styles.active : '',
                activeIndex === i ? activeColors[headerTheme] : '',
              ].join(' ')}
              onClick={() => {
                if (typeof onClick === 'function') {
                  onClick(i)
                }
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

SliderDots.propTypes = {
  number: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  headerTheme: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
}

const mapStateToProps = ({ headerTheme }) => {
  return { headerTheme }
}

export default connect(mapStateToProps)(SliderDots)
