import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import { setHeaderTheme } from '../actions/headerTheme'
import onPageRender from '../hocs/onPageRender'
import LinkedFeatured from '../components/LinkedFeatured/LinkedFeatured'
import { coverThemeNames } from '../constants/coverThemeNames'
import HomeHero from '../components/HomeHero/HomeHero'
import Slider from '../components/Slider/Slider'
import Slide from '../components/Slide/Slide'
import { createProjectsData } from './ProjectsPage'
import LinkedProject from '../components/LinkedProject/LinkedProject'
import AllProjectsSlider from '../components/AllProjectsSlider/AllProjectsSlider'
import AllProjectsSliderItem from '../components/AllProjectsSliderItem/AllProjectsSliderItem'
import { Tablet, Mobile } from '../components/MediaQueries/MediaQueries'
import Footer from '../components/Footer/Footer'
import SliderDots from '../components/SliderDots/SliderDots'
import MobileHomeProjectsTitle from '../components/MobileHomeProjectsTitle/MobileHomeProjectsTitle'
import Head from '../components/Head/Head'

/**
 * @param {Array} nodes - Featured project nodes
 */
function byPrismicIdObj(nodes) {
  let byId = {}

  nodes.forEach(node => {
    const { prismicId } = node
    byId[prismicId] = node
  })

  return { byId }
}

/**
 * @param {Array} nodes - Category nodes
 * @param {String} id - Project id
 */
function getCategoryData(nodes, id) {
  let categoryName = ''
  let categoryTheme = ''

  nodes.forEach(({ data }) => {
    const { cover_theme, title, projects } = data
    const hasProject = projects.some(({ project_link }) => {
      const { document } = project_link
      return id === document.id
    })
    if (hasProject) {
      categoryName = title.text
      categoryTheme = coverThemeNames[cover_theme]
    }
  })

  return { categoryName, categoryTheme }
}

class Homepage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
      parentMousewheel: true,
    }
    this.getSwiper = this.getSwiper.bind(this)
    this.watchUiStyle = this.watchUiStyle.bind(this)
    this.enableParentMousewheel = this.enableParentMousewheel.bind(this)
    this.onDotClick = this.onDotClick.bind(this)
    this.slideToTop = this.slideToTop.bind(this)
  }

  componentDidMount() {
    const { setHeaderTheme } = this.props

    setHeaderTheme('homeHero')

    const headerBrandLink = document.querySelector('#HeaderBrandLink')

    if (!headerBrandLink) return

    headerBrandLink.addEventListener('click', this.slideToTop)
  }

  componentWillUnmount() {
    const headerBrandLink = document.querySelector('#HeaderBrandLink')

    if (!headerBrandLink) return

    headerBrandLink.removeEventListener('click', this.slideToTop)
  }

  slideToTop() {
    this.swiper.slideTo(0, 600)
  }

  getSwiper(swiper) {
    this.swiper = swiper
    this.setActiveSlide()
  }

  setActiveSlide() {
    if (!this.swiper) return

    this.watchUiStyle()

    this.swiper.on('slideChange', () => {
      this.setState({ activeSlide: this.swiper.activeIndex })
      this.watchUiStyle()
    })
  }

  watchUiStyle() {
    const { activeIndex } = this.swiper
    const { setHeaderTheme, data } = this.props
    const { featuredProjects, allCategories } = data
    const { byId } = byPrismicIdObj(featuredProjects.nodes)
    const { featuredProjectIds } = data.page.fields
    const themeByIndex = function() {
      let obj = {}
      featuredProjectIds.forEach((prismicId, i) => {
        const { id } = byId[prismicId]
        const { categoryTheme } = getCategoryData(allCategories.nodes, id)
        obj[i + 1] = categoryTheme === 'brown' ? 'goldOnDark' : categoryTheme
      })
      return obj
    }

    if (activeIndex === 0) {
      setHeaderTheme('homeHero')
    } else if (activeIndex <= featuredProjectIds.length) {
      setHeaderTheme(themeByIndex()[activeIndex])
    } else {
      setHeaderTheme('gray')
    }
  }

  enableParentMousewheel(isEnabled) {
    this.setState({ parentMousewheel: isEnabled })
  }

  onDotClick(index) {
    this.swiper.slideTo(index, 600)
  }

  render() {
    const { data, pageContext } = this.props
    const { lang } = pageContext
    const {
      page,
      featuredProjects,
      allCategories,
      projectsPage,
      allHomepages,
    } = data
    const { fields } = page
    const { featuredProjectIds, pagePath } = fields
    const { byId } = byPrismicIdObj(featuredProjects.nodes)
    const { activeSlide, parentMousewheel } = this.state
    const projectsPageProjects = projectsPage.data.projects
    const projectsPageCategories = projectsPage.data.categories
    const allProjectsData = createProjectsData(
      projectsPageProjects,
      projectsPageCategories
    )
    const projectsPageTitle = projectsPage.data.title.text
    const projectsPagePath = projectsPage.fields.pagePath
    return (
      <>
        <Head path={pagePath} image={page.data.hero_image} />
        <div>
          <Tablet>
            <Slider
              direction='vertical'
              getSwiper={this.getSwiper}
              mousewheel={parentMousewheel}
            >
              <div className='w-full overflow-hidden'>
                <Slide>
                  <HomeHero
                    image={page.data.hero_image}
                    onArrowClick={this.onDotClick}
                    allHomepages={allHomepages.nodes}
                  />
                </Slide>
              </div>
              {featuredProjectIds.map((prismicId, i) => {
                const { id, data, fields } = byId[prismicId]
                const { title, awards, cover_photo } = data
                const { pagePath } = fields
                const { categoryTheme } = getCategoryData(
                  allCategories.nodes,
                  id
                )
                return (
                  <div key={id}>
                    <Slide key={id}>
                      <LinkedFeatured
                        title={title.text}
                        awards={awards.html}
                        image={cover_photo}
                        path={pagePath}
                        theme={categoryTheme}
                        visible={activeSlide === i + 1}
                      />
                    </Slide>
                  </div>
                )
              })}
              <div>
                <AllProjectsSlider
                  enableParentMousewheel={this.enableParentMousewheel}
                  active={activeSlide === featuredProjectIds.length + 1}
                >
                  {allProjectsData.allIds.map(id => {
                    const {
                      data,
                      fields,
                      categoryTitle,
                      theme,
                    } = allProjectsData.byId[id]
                    return (
                      <div key={id}>
                        <AllProjectsSliderItem>
                          <LinkedProject
                            key={id}
                            title={data.title.text}
                            categoryTitle={categoryTitle}
                            path={fields.pagePath}
                            image={data.cover_photo}
                            theme={theme}
                          />
                        </AllProjectsSliderItem>
                      </div>
                    )
                  })}
                </AllProjectsSlider>
                <Footer theme='gray' lang={lang} />
              </div>
            </Slider>
            <SliderDots
              number={featuredProjectIds.length + 2}
              onClick={this.onDotClick}
              activeIndex={activeSlide}
              hidden={activeSlide === featuredProjectIds.length + 1}
            />
          </Tablet>
        </div>
        <div>
          <Mobile>
            <HomeHero
              image={page.data.hero_image}
              allHomepages={allHomepages.nodes}
            />
            <MobileHomeProjectsTitle
              path={projectsPagePath}
              title={projectsPageTitle}
            />
            <div className='px-3'>
              {allProjectsData.allIds.map(id => {
                const {
                  data,
                  fields,
                  categoryTitle,
                  theme,
                } = allProjectsData.byId[id]
                const { awardsShort } = data
                return (
                  <LinkedProject
                    key={id}
                    title={data.title.text}
                    categoryTitle={categoryTitle}
                    path={fields.pagePath}
                    image={data.cover_photo}
                    theme={theme}
                    awards={awardsShort}
                  />
                )
              })}
            </div>
            <Footer theme='gray' lang={lang} positionStatic />
          </Mobile>
        </div>
      </>
    )
  }
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired,
  setHeaderTheme: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTheme: theme => dispatch(setHeaderTheme(theme)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(onPageRender(Homepage))

export const query = graphql`
  query($lang: String!, $featuredProjectIds: [ID]!) {
    page: prismicHomepage(lang: { eq: $lang }) {
      data {
        hero_image {
          ...ImageData
          localFile {
            childImageSharp {
              fixed(width: 380, height: 400, cropFocus: CENTER, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      }
      fields {
        pagePath
        featuredProjectIds
      }
    }
    allHomepages: allPrismicHomepage {
      nodes {
        lang
        fields {
          pagePath
        }
      }
    }
    featuredProjects: allPrismicProject(
      filter: { prismicId: { in: $featuredProjectIds } }
    ) {
      nodes {
        id
        prismicId
        data {
          ...ProjectDataFields
        }
        fields {
          pagePath
        }
      }
    }
    allCategories: allPrismicCategory(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          cover_theme
          title {
            text
          }
          projects {
            project_link {
              document {
                ... on PrismicProject {
                  id
                }
              }
            }
          }
        }
      }
    }
    projectsPage: prismicProjectsPage(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        categories {
          ...ProjectsPageCategoryData
        }
        projects {
          ...ProjectsPageProjectData
        }
      }
      fields {
        pagePath
      }
    }
  }
`
