import React from 'react'

const HomeHeroArrow = () => {
  return (
    <svg
      fill='none'
      height='25'
      viewBox='0 0 23 25'
      width='23'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m.900009 12.1001 10.449991 11m0 0 10.65-11m-10.65 11v-23.000003'
        stroke='#D0D1D2'
        strokeMiterlimit='10'
        strokeWidth='1.25'
      />
    </svg>
  )
}

export default HomeHeroArrow
