import React from 'react'
import PropTypes from 'prop-types'
import { withLocalize, Translate } from 'react-localize-redux'
import GatsbyImage from 'gatsby-image'
import Img from '../Img/Img'
import styles from './HomeHero.module.scss'
import HomeHeroBrand from '../HomeHeroBrand/HomeHeroBrand'
import FadeInOnMount from '../FadeInOnMount/FadeInOnMount'
import HomeHeroArrow from '../HomeHeroArrow/HomeHeroArrow'
import AniLink from '../AniLink/AniLink'

const classes = {
  mobileImage: [
    'xs:hidden',
    'absolute',
    'inset-0',
    //
  ].join(' '),
  image: [
    'hidden',
    'xs:block',
    //
  ].join(' '),
  container: [
    'relative',
    'md:absolute',
    'md:inset-0',
    'overflow-hidden',
    'flex',
    'items-center',
    'justify-center',
    'select-none',
  ].join(' '),
  arrow: [
    'absolute',
    'right-0',
    'bottom-0',
    'left-0',
    'm-auto',
    'pb-6',
    //
  ].join(' '),
  arrowLabel: [
    'block',
    'mr-auto',
    'ml-auto',
    'pb-3',
    'text-center',
    'font-serif-display',
    // 'font-sans-display',
    'font-light',
    'text-gray-200',
    'text-xl',
    '3xl:text-2xl',
    // 'uppercase',
    //
  ].join(' '),
  button: [
    'block',
    'mr-auto',
    'ml-auto',
    'focus:outline-none',
    //
  ].join(' '),
  translations: [
    'flex',
    'justify-center',
    'absolute',
    'right-0',
    'md:left-auto',
    'bottom-0',
    'left-0',
    'mb-6',
    'md:mr-6',
    'text-gray-200',
    'font-sans-display',
    'leading-none',
    'z-10',
    //
  ].join(' '),
}

const HomeHero = ({ image, onArrowClick, languages, allHomepages }) => {
  return (
    <div className={[classes.container, styles.container].join(' ')}>
      <div className={['relative z-10', styles.brand].join(' ')}>
        <FadeInOnMount duration={800}>
          <HomeHeroBrand />
        </FadeInOnMount>
      </div>
      <div className={[classes.mobileImage].join(' ')}>
        <GatsbyImage
          fixed={image.localFile.childImageSharp.fixed}
          className={'absolute inset-0'}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div className={[styles.image, classes.image].join(' ')}>
        <Img data={{ alt: '', ...image }} className='object-cover' />
      </div>
      <FadeInOnMount duration={800} delay={1900}>
        {languages.length > 0 && (
          <div className={classes.translations}>
            {languages.map(({ name, code }, i) => {
              let path = allHomepages.find(({ lang }) => {
                return lang === code
              }).fields.pagePath

              if (path[0] !== '/') path = `/${path}`

              return (
                <div
                  key={i}
                  className={i < languages.length - 1 ? 'mr-3 md:mr-6' : ''}
                >
                  <AniLink to={path} className={styles.translationLink}>
                    <span>{name}</span>
                  </AniLink>
                </div>
              )
            })}
          </div>
        )}
      </FadeInOnMount>
      {onArrowClick && (
        <FadeInOnMount duration={800} delay={1600}>
          <div className={[classes.arrow, styles.arrow].join(' ')}>
            <span className={[classes.arrowLabel, styles.arrowLabel].join(' ')}>
              <Translate id='featured' />
            </span>
            <button
              type='button'
              className={classes.button}
              onClick={() => {
                if (typeof onArrowClick === 'function') {
                  onArrowClick(1)
                }
              }}
            >
              <HomeHeroArrow />
            </button>
          </div>
        </FadeInOnMount>
      )}
    </div>
  )
}

HomeHero.propTypes = {
  image: PropTypes.object.isRequired,
  onArrowClick: PropTypes.func,
}

export default withLocalize(HomeHero)
