import React, { Component } from 'react'
import PropTypes from 'prop-types'
import fitty from 'fitty'
import Img from '../Img/Img'
import AniLink from '../AniLink/AniLink'
import { watchViewport, unwatchViewport } from 'tornis'
import styles from './LinkedFeatured.module.scss'

const SUM_OF_Y_MARGINS = 200
const MULTIPLIER = 1.5

const colors = {
  title: {
    brown: 'text-white',
    gold: 'text-white',
    gray: 'text-white',
    grayDark: 'text-white',
  },
  awards: {
    brown: 'text-gold-100',
    gold: 'text-brown-200',
    gray: 'text-red-100',
    grayDark: 'text-brown-200',
  },
  category: {
    brown: 'text-white',
    gold: 'text-white',
    gray: 'text-white',
    grayDark: 'text-white',
  },
  background: {
    brown: 'bg-brown-300',
    gold: 'bg-gold-100',
    gray: 'bg-gray-200',
    grayDark: 'bg-gray-300',
  },
}

const classes = {
  wrap: [
    'absolute',
    'inset-0',
    'flex',
    'items-center',
    'justify-center',
    'bg-gray-100',
  ].join(' '),
  info: [
    'absolute',
    'inset-0',
    'px-12',
    'text-center',
    'flex',
    'items-center',
  ].join(' '),
  title: [
    'font-serif-display',
    'leading-none',
    'tracking-tighter',
    'uppercase',
  ].join(' '),
  awards: [
    'absolute',
    'right-0',
    'bottom-0',
    'left-0',
    'pt-6',
    'text-center',
    'font-serif-display',
    'text-3xl',
    'leading-none',
    'tracking-tighter',
    'z-10',
  ].join(' '),
  category: [
    'absolute',
    'right-0',
    'left-0',
    'bottom-0',
    'mb-6',
    'font-sans-display',
    'font-light',
    'tracking-tight',
    'text-center',
    'text-3xl',
    'z-10',
  ].join(' '),
  background: ['absolute', 'inset-0'].join(' '),
}

class LinkedFeatured extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 'auto',
      height: 'auto',
    }
    this.setSize = this.setSize.bind(this)
  }

  componentDidMount() {
    fitty(this.title, {
      minSize: 50,
      maxSize: 155,
    })
    watchViewport(this.setSize)
  }

  componentWillUnmount() {
    unwatchViewport(this.setSize)
  }

  setSize({ size }) {
    if (size.changed) {
      const { x, y } = size
      const height = y - SUM_OF_Y_MARGINS
      let width = height * MULTIPLIER

      if (width >= x - 100) width = x - 100

      this.setState({
        width: `${width}px`,
        height: `${height}px`,
      })
    }
  }

  render() {
    const { title, awards, path, image, theme, visible } = this.props
    const { width, height } = this.state
    return (
      <div className={classes.wrap}>
        <div className={styles.container}>
          <div className={styles.frame} style={{ width, height }}>
            <Img data={image} className='object-cover' />
            <div
              className={`${classes.info} ${styles.info} ${
                visible ? styles.visible : ''
              }`}
            >
              <div className={styles.titleContainer}>
                <div
                  className={`${classes.title} ${styles.title} ${colors.title[theme]}`}
                  ref={el => (this.title = el)}
                >
                  <AniLink to={path} state={{ atHomepage: true }}>
                    {title}
                  </AniLink>
                </div>
              </div>
            </div>
            {awards && (
              <div
                className={`${classes.awards} ${styles.awards} ${
                  colors.awards[theme]
                } ${visible ? styles.visible : ''}`}
                dangerouslySetInnerHTML={{
                  __html: awards,
                }}
              />
            )}
          </div>
        </div>
        <div className={`${classes.background} ${colors.background[theme]}`} />
      </div>
    )
  }
}

LinkedFeatured.propTypes = {
  title: PropTypes.string.isRequired,
  awards: PropTypes.string,
  path: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  theme: PropTypes.string,
  visible: PropTypes.bool,
}

export default LinkedFeatured
