import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import { Mousewheel, Scrollbar } from 'swiper/dist/js/swiper.esm.js'
import styles from './AllProjectsSlider.module.scss'
import throttle from '../../utils/throttle'

const swiperParams = {
  modules: [Mousewheel, Scrollbar],
  slidesPerView: 'auto',
  spaceBetween: 24,
  slidesOffsetAfter: 24,
  freeMode: true,
  nested: true,
  speed: 600,
  resistance: false,
  mousewheel: true,
}

class AllProjectsSlider extends Component {
  constructor(props) {
    super(props)
    this.getSwiper = this.getSwiper.bind(this)
    this.handleSlidePrev = this.handleSlidePrev.bind(this)

    this.handleProgress = throttle(() => {
      if (this.swiper.activeIndex === 0) {
        this.props.enableParentMousewheel(true)
      } else {
        this.props.enableParentMousewheel(false)
      }
    }, 300)
  }

  getSwiper(swiper) {
    this.swiper = swiper
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props
    if (prevProps.active !== active && active) {
      this.watchSlides()
    } else if (prevProps.active !== active && !active) {
      this.unwatchSlides()
    }
  }

  watchSlides() {
    if (!this.swiper) return
    this.swiper.on('progress', this.handleProgress)
  }

  unwatchSlides() {
    if (!this.swiper) return
    this.props.enableParentMousewheel(true)
    this.swiper.off('progress', this.handleProgress)
  }

  handleSlidePrev() {
    const { onClickSlidePrev } = this.props

    if (typeof onClickSlidePrev === 'function') {
      onClickSlidePrev()
    }

    if (!this.swiper) return

    this.swiper.slideTo(0, 600)
  }

  render() {
    const { children } = this.props
    return (
      <div className={styles.container} ref={el => (this.container = el)}>
        <Swiper getSwiper={this.getSwiper} {...swiperParams}>
          <div>
            <div style={{ width: '5px' }} />
          </div>
          {children}
        </Swiper>
      </div>
    )
  }
}

AllProjectsSlider.propTypes = {
  children: PropTypes.node.isRequired,
  enableParentMousewheel: PropTypes.func.isRequired,
  projectsTitle: PropTypes.string,
}

export default AllProjectsSlider
