import React from 'react'

const HomeHeroBrand = () => {
  return (
    <svg
      enableBackground='new 0 0 601 140'
      height='140'
      viewBox='0 0 601 140'
      width='601'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='#faf7f6'>
        <path d='m409.5 58v19.7c-9.3 2.7-18.1 4.1-26.4 4.1-11 0-20.2-3.6-27.6-10.7s-11.1-16.2-11.1-27.3c0-5.8 1.2-11.2 3.6-16.1 4.4-9.1 11.9-15.7 22.3-19.7 4.8-1.8 9.3-2.7 13.5-2.7 4.3 0 8.6.4 13.2 1.2 4.5.8 8 1.7 10.3 2.6v14.4h-.5c-5.7-10.6-13.4-15.9-23.1-15.9-7.9 0-14.7 3.3-20.4 10s-8.6 15.3-8.6 25.7 2.9 18.9 8.7 25.4 13 9.8 21.5 9.9c3 0 6.1-.3 9.1-1s5.3-1.3 6.7-2v-17.6c0-4.5-.6-7.7-1.8-9.7s-3.6-3.4-7.1-4.4v-.4h24.4v.4c-2.6 1-4.4 2.4-5.4 4.2-.8 1.7-1.3 5-1.3 9.9' />
        <path d='m487 79.4c3.9 0 7.2-1.4 9.9-4.1 5.1-5.1 7.6-12.5 7.6-22.3 0-7.3-1.5-13.5-4.7-18.6s-7.5-7.6-12.9-7.6-9.7 2.5-12.8 7.6-4.7 11.3-4.7 18.6c0 9.7 2.5 17.1 7.6 22.3 2.8 2.7 6.1 4.1 10 4.1m19.4-6.3c-5.4 5.6-11.9 8.4-19.4 8.4-7.6 0-14-2.8-19.4-8.3-5.4-5.6-8-12.3-8-20.3s2.7-14.6 8.1-20.1c5.4-5.4 11.9-8.1 19.3-8.1s13.9 2.7 19.3 8.2c5.4 5.4 8.2 12.1 8.1 20 .1 7.8-2.6 14.6-8 20.2' />
        <path d='m522.5 63h.5c4.7 10.8 10.6 16.3 17.5 16.3 3 0 5.6-1 7.7-2.9s3.2-4.2 3.2-6.7c0-2.6-1-4.8-2.9-6.6s-4.3-3.4-7.1-4.7-5.6-2.7-8.4-4.2-5.2-3.5-7.1-6-2.9-5.4-2.9-8.8c0-4.5 1.7-8.1 5-10.8s7.3-4 11.9-4 9.4 1 14.4 3.1v12.9h-.5c-4-9.2-9-13.8-15-13.8-2.6 0-4.9.9-6.8 2.6s-2.9 3.9-2.9 6.4c0 2.6 1 4.7 2.9 6.4 2 1.7 4.4 3.2 7.2 4.5 2.8 1.2 5.6 2.6 8.4 4s5.2 3.4 7.2 5.9 3 5.8 3 9.9-1.7 7.6-5.1 10.6-8 4.4-13.7 4.4-11.2-1.5-16.6-4.4v-14.1z' />
        <path d='m565.6 63h.5c4.7 10.8 10.6 16.3 17.5 16.3 3 0 5.6-1 7.7-2.9s3.2-4.2 3.2-6.7c0-2.6-1-4.8-2.9-6.6s-4.3-3.4-7.1-4.7-5.6-2.7-8.4-4.2-5.2-3.5-7.1-6-2.9-5.4-2.9-8.8c0-4.5 1.7-8.1 5-10.8s7.3-4 11.9-4 9.4 1 14.4 3.1v12.9h-.4c-4-9.2-9-13.8-15-13.8-2.6 0-4.9.9-6.8 2.6s-2.9 3.9-2.9 6.4c0 2.6 1 4.7 2.9 6.4 2 1.7 4.4 3.2 7.2 4.5 2.8 1.2 5.6 2.6 8.4 4s5.2 3.4 7.2 5.9 3 5.8 3 9.9-1.7 7.6-5.1 10.6-8 4.4-13.7 4.4-11.2-1.5-16.6-4.4z' />
        <path d='m456.4 26.4c1.2 1.2 1.8 2.6 1.8 4.3s-.6 3.1-1.8 4.3c-1.2 1.1-2.6 1.7-4.3 1.7s-3.1-.6-4.3-1.8-1.8-2.6-1.8-4.3.6-3.1 1.8-4.3 2.6-1.8 4.3-1.8c1.7.2 3.1.7 4.3 1.9m-17.4-1.7v42.1c0 4.5.6 7.5 1.8 9.1s3.6 2.9 7.4 3.8v.4h-25.3v-.4c3.2-1 5.3-2.3 6.3-3.9s1.5-4.6 1.4-9.1v-23.5c0-4.7-.5-7.8-1.5-9.3-1-1.6-3.5-2.9-7.5-4v-.4c4.6-.2 10.3-1.8 17-4.8z' />
        <path d='m1 59.8h.4c4.6 13.1 11.4 19.6 20.2 19.6 3.9 0 7.3-1.3 10.3-3.9 2.9-2.6 4.4-5.8 4.4-9.5s-1.2-7-3.6-9.9c-2.4-2.8-5.3-5.3-8.8-7.3-3.4-2.1-6.8-4.2-10.3-6.2-3.4-2.1-6.3-4.7-8.8-7.8-2.4-3.1-3.6-6.6-3.6-10.6 0-5.6 2.2-10.1 6.5-13.6 4.3-3.6 9.2-5.3 14.6-5.3s11.5 1.4 18.3 4.2v14.6h-.4c-4.6-11-10.9-16.4-18.8-16.5-3.7 0-6.7 1.2-9.2 3.5s-3.7 5.2-3.7 8.6 1.2 6.5 3.7 9.2 5.4 5.1 8.9 7.1 6.9 4 10.4 6.2c3.5 2.1 6.4 4.9 8.8 8.1 2.5 3.3 3.7 6.9 3.6 11 0 6-2.3 11-6.8 14.8s-10.2 5.7-16.9 5.7c-6.8-.1-13.2-1.5-19.2-4.3z' />
        <path d='m147.4 66.7v-24c0-8.4-4.2-12.6-12.5-12.7-4.1 0-8.8 1.5-14.1 4.6v32.4c0 4.3.5 7.3 1.5 8.9s3.1 2.9 6.3 3.9v.4h-23.9v-.4c3.2-1 5.3-2.3 6.3-3.9s1.5-4.5 1.5-8.8v-22.7c0-4.1-.2-7.1-.7-8.9-.4-1.8-1.2-3.1-2.3-4-1.1-.8-3.1-1.6-6-2.4v-.4c5.7-.4 11.3-1.8 16.9-4.1h.4v8.4c7-5.6 13.5-8.4 19.6-8.4 4.5 0 8.3 1.5 11.2 4.4s4.4 7.5 4.4 13.7v23.9c0 4.4.5 7.4 1.5 9.1 1 1.6 3 3 6.2 4v.4h-23.9v-.4c3.2-1 5.3-2.3 6.2-4 .9-1.6 1.4-4.6 1.4-9' />
        <path d='m181.5 34.2c-3.3 4.6-4.9 10.5-4.9 17.7s1.8 12.8 5.4 16.8 8.5 6.1 14.6 6.1c4.2 0 8.6-1.6 13.2-4.7v-37.3c-4.5-3.7-9.6-5.5-15.1-5.5s-9.9 2.3-13.2 6.9m37-34.2v60.9c0 6.2.5 10.1 1.5 11.8s3.5 3 7.6 4v.5c-6.1.6-11.9 2-17.3 4.3h-.4v-10.2c-2 2.6-4.8 5-8.2 7s-7.2 3.1-11.4 3.1c-6.1 0-11.4-2.4-15.8-7.2s-6.6-10.9-6.6-18.4c0-9.7 3-17.4 8.9-22.9s13.1-8.3 21.7-8.3c3.2 0 7 .4 11.4 1.3v-8c0-4.7-.5-7.8-1.7-9.2-1.2-1.5-3.8-2.7-7.8-3.5v-.4c7-.6 12.9-2.2 17.8-4.8z' />
        <path d='m265.3 26.4c1.2 1.2 1.8 2.6 1.8 4.3s-.6 3.1-1.8 4.3c-1.2 1.1-2.6 1.7-4.3 1.7s-3.1-.6-4.3-1.8-1.8-2.6-1.8-4.3.6-3.1 1.8-4.3 2.6-1.8 4.3-1.8c1.7.2 3.2.7 4.3 1.9m-17.1-1.7v42.1c0 4.5.6 7.5 1.8 9.1s3.6 2.9 7.4 3.8v.4h-25.3v-.4c3.2-1 5.3-2.3 6.3-3.9s1.5-4.6 1.4-9.1v-23.5c0-4.7-.5-7.8-1.5-9.3-1-1.6-3.5-2.9-7.5-4v-.4c4.6-.2 10.3-1.8 17-4.8z' />
        <path d='m295.9 73.4c-3.3 2-6.8 2.9-10.4 2.9s-6.6-.9-8.7-2.7c-2.2-1.8-3.5-5.3-3.3-9.1.2-3.2 1.5-6.1 4.5-8.5s7.1-3.6 12.1-3.6c1.9 0 3.9.2 5.8.6zm12.6 2.9c-2.9-1-4.3-3.9-4.3-8.7v-28.1c0-4.7-1.4-8.4-4.3-10.9-2.9-2.6-6.8-3.8-11.9-3.8s-9.3 2.8-9.3 2.8l.7.7c3.9-1.5 8.8-1.8 12.6 2.4 2.7 2.8 3.9 7.5 3.8 13v6.6c-11.1.4-19 2.2-23.8 5.5-4.8 3.2-7.1 7.1-7.1 11.6s1.4 8 4.2 10.5 6.2 3.7 10.1 3.7c5.5 0 11.1-2.5 16.7-7.5.1 2.3.7 4 2 5.2 1.2 1.2 2.7 1.8 4.5 1.8s4-.4 6.7-1.1c2.7-.8 4.5-1.4 5.5-1.8v-.4c-2.5-.5-4.5-.9-6.1-1.5' />
        <path d='m60.4 64.8c0 3.8 1.1 6.5 3.2 8.3s5 2.7 8.6 2.7 7.1-1 10.3-2.9v-20.2c-1.9-.3-3.8-.5-5.7-.6-5 0-9 1.2-12 3.5-2.9 2.4-4.4 5.4-4.4 9.2m-4.2-29.3s8.3-10.8 18.5-10.8c5 0 9 1.3 11.8 3.8 2.9 2.5 4.3 6.1 4.3 10.8v27.9c0 4.7 1.4 7.6 4.3 8.6 1.6.6 3.6 1 6.1 1.2v.4c-1 .4-2.8 1-5.5 1.8s-4.9 1.1-6.7 1.1-3.2-.6-4.5-1.8c-1.2-1.2-1.9-2.9-2-5.2-5.5 5.1-11 7.5-16.5 7.6-3.9 0-7.2-1.2-10.1-3.6-2.8-2.4-4.2-5.9-4.2-10.4s2.4-8.3 7.1-11.5 12.7-5 23.7-5.4v-6.6c0-10.2-3-15.3-11.6-15.3-7.5 0-13.9 7.8-13.9 7.8z' />
        <path d='m457.6 125.6.6-1.8.6 1.8 2.3 6.5h-5.8zm8.5 13.5-6.6-17.1h-2.5l-6.6 17.1h2.4l1.9-5h7.3l1.8 5zm-37.9-17.1h-2.2v17.1h2.2zm-24.2 0h-11.7v17.1h2.2v-7.8h7.8v-1.9h-7.8v-5.4h9.5zm-42.2 3.6.6-1.8.6 1.8 2.3 6.5h-5.8zm8.4 13.5-6.6-17.1h-2.5l-6.6 17.1h2.4l1.9-5h7.3l1.8 5zm-48.7-9.3v-5.8h5.3c1.1 0 1.9.2 2.5.7s.9 1.2.9 2.1c0 1-.3 1.7-1 2.2s-1.6.8-2.7.8zm10.5 3.9c-.2-1.6-1-2.5-2.6-2.9.9-.3 1.7-.8 2.2-1.5.6-.8.8-1.7.8-2.8 0-1.4-.5-2.5-1.4-3.3s-2.2-1.2-3.8-1.2h-7.9v17.1h2.2v-7.4h5.3c1 0 1.7.2 2.2.6s.8 1.2.9 2.2l.5 4.6h2.3zm-43.6-1.8h5.2v.9c-.3 1.5-.9 2.6-1.9 3.4-.9.8-2.1 1.2-3.6 1.2-1.8 0-3.3-.6-4.3-1.9s-1.6-2.9-1.6-5c0-2.2.5-3.9 1.6-5.1s2.6-1.9 4.5-1.9c1.3 0 2.4.3 3.3.9s1.4 1.5 1.7 2.6l2.2-.1c-.3-1.7-1-3-2.4-3.9-1.3-.9-3-1.4-4.9-1.4-2.6 0-4.6.8-6 2.4-1.5 1.6-2.2 3.8-2.2 6.5 0 2.8.8 5 2.3 6.6s3.5 2.4 5.8 2.4c2.5 0 4.4-.9 5.6-2.7l.5 2.3h1.6v-9.1h-7.4zm-43.1 3.7c-1.1-1.3-1.6-3-1.6-5.1s.5-3.8 1.6-5c1.1-1.3 2.5-1.9 4.2-1.9s3.1.6 4.2 1.9c1.1 1.2 1.6 2.9 1.6 5s-.5 3.8-1.6 5.1-2.5 1.9-4.2 1.9-3.1-.6-4.2-1.9m4.2 3.9c2.4 0 4.3-.8 5.9-2.5 1.5-1.7 2.2-3.8 2.2-6.5 0-2.6-.7-4.7-2.2-6.4s-3.5-2.5-5.9-2.5-4.3.8-5.8 2.5-2.3 3.8-2.3 6.4.8 4.8 2.3 6.4c1.5 1.8 3.4 2.6 5.8 2.6m-42.7-17.5v1.9h5.7v15.2h2.2v-15.2h5.7v-1.9zm-33.3 13.6c-1.1-1.3-1.6-3-1.6-5.1s.5-3.8 1.6-5c1.1-1.3 2.5-1.9 4.2-1.9s3.1.6 4.2 1.9c1.1 1.2 1.6 2.9 1.6 5s-.5 3.8-1.6 5.1-2.5 1.9-4.2 1.9-3.1-.6-4.2-1.9m4.2 3.9c2.4 0 4.3-.8 5.9-2.5 1.5-1.7 2.2-3.8 2.2-6.5 0-2.6-.7-4.7-2.2-6.4s-3.5-2.5-5.9-2.5-4.3.8-5.8 2.5-2.3 3.8-2.3 6.4.8 4.8 2.3 6.4c1.5 1.8 3.4 2.6 5.8 2.6m-28.8-17.5h-11.7v17.1h2.2v-7.8h7.8v-1.9h-7.8v-5.4h9.5z' />
      </g>
    </svg>
  )
}

export default HomeHeroBrand
