import React from 'react'
import PropTypes from 'prop-types'
import AniLink from '../AniLink/AniLink'
import styles from './MobileHomeProjectsTitle.module.scss'

const classes = {
  container: [
    'py-12',
    'sm:py-20',
    'text-center',
    //
  ].join(' '),
  link: [
    'relative',
    'font-serif-display',
    'uppercase',
    'leading-none',
    'tracking-tight',
    'text-2xl',
    'sm:text-3xl',
    'text-red-100',
    //
  ].join(' '),
}

const MobileHomeProjectsTitle = ({ path, title }) => {
  return (
    <div className={classes.container}>
      <AniLink to={path} className={[classes.link, styles.link].join(' ')}>
        <span>{title}</span>
      </AniLink>
    </div>
  )
}

MobileHomeProjectsTitle.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default MobileHomeProjectsTitle
