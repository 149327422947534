import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { watchViewport, unwatchViewport } from 'tornis'
import styles from './AllProjectsSliderItem.module.scss'

const classes = {
  item: ['flex', 'items-center'].join(' '),
}

export class AllProjectsSliderItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 'auto',
    }
    this.setSize = this.setSize.bind(this)
  }

  componentDidMount() {
    watchViewport(this.setSize)
  }

  componentWillUnmount() {
    unwatchViewport(this.setSize)
  }

  setSize({ size }) {
    if (size.changed) {
      const { y } = size
      this.setState({
        height: `${y}px`,
      })
    }
  }

  render() {
    const { children } = this.props
    const { height } = this.state
    return (
      <div className={`${styles.item} ${classes.item}`} style={{ height }}>
        {children}
      </div>
    )
  }
}

AllProjectsSliderItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AllProjectsSliderItem
